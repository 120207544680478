import React from "react";
import "./Products.scss";
import { useNavigate } from "react-router-dom";
// icons for .header
import PlusIcon from "../../Files/Icons/plus-square.svg";
import ListIcon from "../../Files/Icons/list.svg";

import { ReactComponent as BoxIcon } from "../../Files/Icons/box.svg";

// icons for bottom navbar
import { ReactComponent as HouseIcon } from "../../Files/Icons/house.svg";
import { ReactComponent as SearchIcon } from "../../Files/Icons/search.svg";
import { ReactComponent as HeartIcon } from "../../Files/Icons/heart.svg";
import { ReactComponent as PersonCircleIcon } from "../../Files/Icons/person-circle.svg";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AddBoxIcon from '@mui/icons-material/AddBox';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
export default function Products(props) {
    const navigate = useNavigate();
    return (
        <div className="home">
      <div className="header">
        <label>Звено жизни</label>
        <span>
          <img src={PlusIcon} alt="" />
        </span>
        <span>
          <img src={ListIcon} alt="" />
        </span>
      </div>
      <div className="products-body">
        <div className="products-publish">
        <div className="publish-header">
          <span>LOGO</span>
          <small>Тото шото</small>
          <small>Где-то там</small>
          <span><DragIndicatorIcon /></span>
          </div>
          <span className="publish">Публикация</span>
        <div className="publish-body">
          <span ><FavoriteBorderIcon /></span>
          <span ><CommentIcon /></span>
          <span ><SendIcon /></span>
          <span ><StickyNote2Icon /></span>
          <small>Нравится: 00555555</small>
          <small>Тото шото <label>вот там то у нас родилось то-то пото вот именно там именно вот</label></small>
          <label>Смотреть коментарии: (001)</label>
          </div>
        </div>
        <div className="products-publish">
        <div className="publish-header">
          <span>LOGO</span>
          <small>Тото шото</small>
          <small>Где-то там</small>
          <span><DragIndicatorIcon /></span>
          </div>
          <span className="publish">Публикация</span>
        <div className="publish-body">
          <span ><FavoriteBorderIcon /></span>
          <span ><CommentIcon /></span>
          <span ><SendIcon /></span>
          <span ><StickyNote2Icon /></span>
          <small>Нравится: 00555555</small>
          <small>Тото шото <label>вот там то у нас родилось то-то пото вот именно там именно вот</label></small>
          <label>Смотреть коментарии: (001)</label>
          </div>
        </div>
        <div className="products-publish">
        <div className="publish-header">
          <span>LOGO</span>
          <small>Тото шото</small>
          <small>Где-то там</small>
          <span><DragIndicatorIcon /></span>
          </div>
          <span className="publish">Публикация</span>
        <div className="publish-body">
          <span ><FavoriteBorderIcon /></span>
          <span ><CommentIcon /></span>
          <span ><SendIcon /></span>
          <span ><StickyNote2Icon /></span>
          <small>Нравится: 00555555</small>
          <small>Тото шото <label>вот там то у нас родилось то-то пото вот именно там именно вот</label></small>
          <label>Смотреть коментарии: (001)</label>
          </div>
        </div>
        <div className="products-publish">
        <div className="publish-header">
          <span>LOGO</span>
          <small>Тото шото</small>
          <small>Где-то там</small>
          <span><DragIndicatorIcon /></span>
          </div>
          <span className="publish">Публикация</span>
        <div className="publish-body">
          <span ><FavoriteBorderIcon /></span>
          <span ><CommentIcon /></span>
          <span ><SendIcon /></span>
          <span ><StickyNote2Icon /></span>
          <small>Нравится: 00555555</small>
          <small>Тото шото <label>вот там то у нас родилось то-то пото вот именно там именно вот</label></small>
          <label>Смотреть коментарии: (001)</label>
          </div>
        </div>
        <div className="products-publish">
        <div className="publish-header">
          <span>LOGO</span>
          <small>Тото шото</small>
          <small>Где-то там</small>
          <span><DragIndicatorIcon /></span>
          </div>
          <span className="publish">Публикация</span>
        <div className="publish-body">
          <span ><FavoriteBorderIcon /></span>
          <span ><CommentIcon /></span>
          <span ><SendIcon /></span>
          <span ><StickyNote2Icon /></span>
          <small>Нравится: 00555555</small>
          <small>Тото шото <label>вот там то у нас родилось то-то пото вот именно там именно вот</label></small>
          <label>Смотреть коментарии: (001)</label>
          </div>
        </div>
        <div className="products-publish">
        <div className="publish-header">
          <span>LOGO</span>
          <small>Тото шото</small>
          <small>Где-то там</small>
          <span><DragIndicatorIcon /></span>
          </div>
          <span className="publish">Публикация</span>
        <div className="publish-body">
          <span ><FavoriteBorderIcon /></span>
          <span ><CommentIcon /></span>
          <span ><SendIcon /></span>
          <span ><StickyNote2Icon /></span>
          <small>Нравится: 00555555</small>
          <small>Тото шото <label>вот там то у нас родилось то-то пото вот именно там именно вот</label></small>
          <label>Смотреть коментарии: (001)</label>
          </div>
        </div>
   
       
      </div>
      <div className="navbar">
        <BottomNavigation value={"Personal"}>
        <BottomNavigationAction value={"Home"} icon={<HouseIcon />} onClick={() => navigate("/")}/>
          <BottomNavigationAction value={"Search"} icon={<SearchIcon />} onClick={() => navigate("/search")} />
          <BottomNavigationAction value={"Box"} icon={<BoxIcon />}/>
          <BottomNavigationAction value={"Heart"} icon={<HeartIcon />} onClick={() => navigate("/notification")}/>
          <BottomNavigationAction value={"Personal"} icon={<PersonCircleIcon />} onClick={() => navigate("/products")}/>
        </BottomNavigation>
      </div>
    </div>

    );
}