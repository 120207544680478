import React, { useState, useEffect } from "react";
import "./Points.scss";

import Header from "../../Components/Header/Header";

import Footer from "../../Components/Footer/Footer";

import { useNavigate } from "react-router-dom";

import BuyIcon from "../../Files/Images/buy_icon.png";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CopyIcon from "../../Files/Icons/copy-icon.png";

import axios from "axios";

import Alert from "../../Components/Alert/Alert";

export default function Points() {
  const navigate = useNavigate();

  const [userId, setUserId] = useState(0);

  // количество баллов
  const [points, setPoints] = useState("");

  // история переводов и начислений
  const [transactions, setTransactions] = useState([]);

  const [transferModal, setTransferModal] = useState({
    visible: false,
    sum: "",
    to: "",
  });

  const [alertData, setAlertData] = useState({
    open: false,
    title: "",
    content: "",
    handleClose: () => setAlertData((prev) => ({ ...prev, open: false })),
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = snackbar;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token === "null") {
      setAlertData({
        open: true,
        title: "Внимание!",
        content: "Для просмотра этой страницы необходимо войти в аккаунт.",
        handleClose: () => navigate("/"),
      });
    }

    axios
      .get("https://апи.звеножизни.рф/api/pages/points", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUserId(res.data.userId);
        setPoints(res.data.points);
        setTransactions(res.data.transactions);
      })
      .catch((error) => {
        setAlertData({
          open: true,
          title: "Ошибка",
          content:
            "Во время загрузки страницы что-то пошло не так. Мы уже разибраемся.",
          handleClose: () => navigate("/"),
        });
      });
  }, [navigate]);

  const TransactionType = (from, to) => {
    if (from === userId && to === 3) {
      return "Покупка";
    }

    if (from === userId && to !== 3) {
      return "Перевод";
    }
  };

  const copyRefLink = () => {
    try {
      navigator.clipboard.writeText(`https://звеножизни.рф/signup/${userId}`);
      setSnackbar((prev) => ({
        ...prev,
        visible: true,
      }));
    } catch (error) {
      setAlertData({
        visible: true,
        title: "Что-то пошло не так",
        data: "Не удалось скопировать ссылку",
      });
    }
  };

  const transferPoints = () => {
    console.log(transferModal.to);
    if (transferModal.to === "" || typeof transferModal.to === "undefined") {
      return alert("Для продолжения необходимо указать получателя.");
    }

    if (transferModal.sum === "" || typeof transferModal.sum === "undefined") {
      return alert("Для продолжения необходимо указать сумму.");
    }

    if (Number(transferModal.sum) < points) {
      return setAlertData((prev) => ({
        ...prev,
        title: "Внимание",
        content: "Недостаточно баллов",
      }));
    }

    let token = localStorage.getItem("token");

    axios
      .get(
        `https://апи.звеножизни.рф/api/points/transfer-points?to=${Number(
          transferModal.to
        )}&sum=${Number(transferModal.sum)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAlertData((prev) => ({
          ...prev,
          open: true,
          title: "Успешно",
          data: "Перевод совершен успешно.",
        }));

        setPoints(res.data);
      })
      .catch((err) => {
        console.error(err.response.data);
        setAlertData((prev) => ({
          ...prev,
          open: true,
          title: "Ошибка",
          data: err.response.data,
        }));
      });
  };

  const validateNumInput = (event) => {
    const keyValue = event.key;
    const isNumeric = /^[0-9]*$/.test(keyValue); // Check if key pressed is only numbers

    if (!isNumeric && keyValue !== "Backspace") {
      event.preventDefault(); // block the key press
    }
  };

  return (
    <div className="points">
      <Header />
      <label className="userId">
        Ваш идентификатор: <i>{userId}</i>
      </label>
      <label className="points-sum">{points} Баллов</label>
      <div className="copy-ref-link" onClick={() => copyRefLink()}>
        <img src={CopyIcon} alt="" />
        <label>Скопировать реферальную ссылку</label>
      </div>
      <div className="pints-actions">
        <button
          type="button"
          onClick={(e) =>
            setTransferModal({
              visible: true,
            })
          }
        >
          Перевести
        </button>
        <button>Статистика рефералов</button>
      </div>
      <div className="transactions">
        <label className="transactions-title">История транзакций</label>
        <div className="histories">
          {transactions.length !== 0 ? (
            transactions.map((t) => {
              return (
                <div className="history">
                  {/* <div className="icon" /> */}
                  <div className="icon">
                    <img src={BuyIcon} alt="" />
                  </div>
                  <div className="content">
                    <label className="reason">
                      {TransactionType(t.from, t.to)}
                    </label>
                    <label className="date">t.</label>
                  </div>
                  <label className="sum">-10 Б.</label>
                </div>
              );
            })
          ) : (
            <label className="empty-transactions">
              Здесь пока ничего нет.
              <br /> Совершите покупку или перевод для отображения ее здесь.
            </label>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={transferModal.visible}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={transferModal.visible}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "50%",
              left: "50%",
              gap: "1rem",
              borderRadius: "10px",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "white",
              boxShadow: 10,
              lineHeight: "2",
            }}
          >
            <div className="transfer-modal">
              <div className="transfer-header">
                <h2>Перевод</h2>
              </div>
              <div className="transfer-body">
                <div>
                  <label>Получатель</label>
                  <input
                    value={transferModal.to}
                    onChange={(e) =>
                      setTransferModal((prev) => ({
                        ...prev,
                        to: e.target.value,
                      }))
                    }
                    onKeyDown={(e) => validateNumInput(e)}
                    type="number"
                    min={1}
                    placeholder="Идентификатор получателя"
                  />
                </div>
                <div>
                  <label>Сумма</label>
                  <input
                    value={transferModal.sum}
                    onChange={(e) =>
                      setTransferModal((prev) => ({
                        ...prev,
                        sum: e.target.value,
                      }))
                    }
                    type="number"
                    onKeyDown={(e) => validateNumInput(e)}
                    placeholder="Сумма, которую желаете перевести"
                    min={1}
                  />
                </div>
              </div>
              <div className="transfer-footer">
                <button onClick={() => transferPoints()}>Отправить</button>
                <button
                  onClick={() =>
                    setTransferModal({
                      visible: false,
                    })
                  }
                >
                  Закрыть
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        severity="success"
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={1800}
        style={{ backgroundColor: "red!important" }}
        open={snackbar.visible}
        onClose={() =>
          setSnackbar((prev) => ({
            ...prev,
            visible: false,
          }))
        }
        // message="Успешно скопировано!"
        key={vertical + horizontal}
      >
        <SnackbarContent
          style={{
            backgroundColor: "whitesmoke",
            color: "black",
            borderColor: "lightgray",
          }}
          message={<span>Успешно скопировано.</span>}
        ></SnackbarContent>
      </Snackbar>
      <Alert
        open={alertData.open}
        title={alertData.title}
        content={alertData.content}
        handleClose={alertData.handleClose}
      />
      <Footer />
    </div>
  );
}
