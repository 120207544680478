import React, { useEffect, useState } from "react";
import "./Notification.scss";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

export default function Notification() {
  const navigate = useNavigate();
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token === null) return;

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.length);
        console.log("RESP => ", response.data.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setCartCount]);

  return (
    <>
      <Header count={cartCount} />
      <div className="notifications">
        <div className="notification-body">
          <div className="notification">
            <div className="logo"></div>
            <div className="notification-content">
              <label>
                Встречайте! Набор "Золотой" уже доступен для покупки!{" "}
                <span>1 д.</span>
              </label>
            </div>
          </div>
          <div className="notification">
            <div className="logo"></div>
            <div className="notification-content">
              <label>
                Акция! Пригласи зарегистрироваться друга по пригласительной
                ссылке и получи 200 баллов на покупки. <span>3 д.</span>
              </label>
            </div>
          </div>
        </div>
        <Footer pageName="Notifications" />
      </div>
    </>
  );
}
