import React, { useState, useEffect } from "react";
import "./SignUp.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import axios from "axios";
import validator from "validator";
import { useNavigate, useParams } from "react-router-dom";

import { Modal, Box, Backdrop, Typography } from "@mui/material";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../Files/Icons/logo.svg";
import Iframe from "react-iframe";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import LogoImage from "../../Files/Images/logo.jpg";
import Button from "@mui/material/Button";

import Fade from "@mui/material/Fade";

const ModalStyle = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "white",
  boxShadow: 10,
  p: 3,
  lineHeight: "2",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 3,
  overflowY: "scroll",
  borderRadius: "10px",
};

export default function SignUp(props) {
  const navigate = useNavigate();

  const params = useParams();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [inviter, setInviter] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorData, setErrorData] = useState("");
  //пользовательское соглашение
  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  useEffect(() => {
    setInviter(Number(params.code));
  }, [params]);

  const signUp = () => {
    try {
      if (!validator.isEmail(email)) {
        return alert("Введен некорректный адрес эл. почты.");
      }

      if (password.length <= 5) {
        return alert("Длина пароля должна составлять 5 и более символов.");
      }
      if (!isSubscribed) {
        return alert("Вы не приняли пользовательское соглашение.");
      }

      setBackdropOpen(true);

      // console.log(isNaN(parseFloat(inviter)));

      axios
        .post("https://апи.звеножизни.рф/api/auth/signup", {
          inviterId: isNaN(parseFloat(inviter)) ? 0 : inviter,
          email: email,
          password: password,
        })
        .then(function (resp) {
          console.log(resp);
          localStorage.setItem("username", resp.data.username);
          localStorage.setItem("id", resp.data.id);

          axios
            .post("https://апи.звеножизни.рф/api/auth/signin", {
              email: email,
              password: password,
            })
            .then(function (resp) {
              localStorage.setItem("token", resp.data.token);
              navigate("/");
            })
            .catch(function (error) {
              setBackdropOpen(false);
              setErrorData(error.response.data);
              setModalOpen(!modalOpen);
            });
        })
        .catch(function (error) {
          console.log(error);
          setBackdropOpen(false);
          setErrorData(error.response.data);
          setModalOpen(!modalOpen);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const validateNumInput = (event) => {
    const keyValue = event.key;
    const isNumeric = /^[0-9]*$/.test(keyValue); // Check if key pressed is only numbers

    if (!isNumeric && keyValue !== "Backspace") {
      event.preventDefault(); // block the key press
    }
  };

  return (
    <div className="signup">
      <span className="com-logo">
        <div
          className="company-logo"
          style={{ backgroundImage: `url(${Logo})` }}
        />
      </span>
      <label className="logo-label">Звено жизни</label>
      <label>Регистрация</label>
      <div className="signup-form">
        {/* <label>Укажите свой E-mail</label> */}
        {/* <h4>Укажите свой E-mail, на который придёт пароль для входа</h4> */}
        <input
          name="invite-code"
          type="number"
          value={inviter}
          placeholder="Код пригласителя"
          onChange={(e) => setInviter(e.target.value)}
          // onKeyDown={(event) => validateNumInput(event)}
        ></input>
        <input
          name="email"
          type="email"
          value={email}
          placeholder="Эл. почта"
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          value={password}
          name="password"
          type={"password"}
          placeholder="Пароль"
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <FormGroup sx={{ display: "block" }}>
          <FormControlLabel
            value={isSubscribed}
            onChange={() => setIsSubscribed(!isSubscribed)}
            sx={{ width: "100%" }}
            control={<Checkbox />}
            label={
              <Typography
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: ".5rem",
                }}
              >
                Я принимаю условия{" "}
                <label
                  onClick={handleOpen4}
                  className="underline"
                  style={{ fontSize: "1rem" }}
                >
                  {" "}
                  пользовательского соглашения
                </label>
              </Typography>
            }
          />
        </FormGroup>
        <button onClick={() => signUp()}>Далее</button>
        <button onClick={() => navigate("/signin")}>
          Уже есть аккаунт? Войти
        </button>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "scroll",
        }}
      >
        <Typography variant="div">
          <Box sx={ModalStyle}>
            <Box style={{ margin: "auto", textAlign: "center" }}>
              {errorData}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <button
                className="btn-close"
                onClick={() => setModalOpen(!modalOpen)}
              >
                Закрыть
              </button>
            </Box>
          </Box>
        </Typography>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Пользовательское соглашение */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open4}
        onClose={handleClose4}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open4}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Пользовательское соглашение
            </Typography>
            {/* <Button onClick={(e) => setFullScreen(true)} sx={{width: "100%", margin: "1rem 0"}} variant="outlined">Открыть в новом окне</Button> */}
            <Iframe
              url="https://docs.google.com/document/d/e/2PACX-1vQrb1X8AtqStEYsUymglANhnmucNqBDHJetlctdQjW7ltyQuF0cvD5kqraZ156mTM1mCWQBZ3EMeoV1/pub"
              width="100%"
              height="90%"
              allowfullscreen="allowfullscreen"
              id=""
              className=""
              styles={{ transform: "scale(1.0)" }}
              display="block"
              position="relative"
            />

            <Button
              onClick={handleClose4}
              sx={{ width: "100%", margin: "1rem 0" }}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
