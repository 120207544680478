import React, { useCallback, useEffect, useState } from "react";
import "./Search.scss";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import { Skeleton } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import axios from "axios";

export default function Search() {
  const [cartCount, setCartCount] = useState(0);

  const [products, setProducts] = useState([]);

  const [isLoaded, setLoaded] = useState(true);

  const [word, setWords] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token === null) return;

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setCartCount]);

  const addToCart = (productId) => {
    let token = localStorage.getItem("token");

    if (token === null) {
      return alert(
        "Чтобы добавить товар в корзину необходимо пройти регистрацию или войти в аккаунт."
      );
    }

    axios
      .post(
        `https://апи.звеножизни.рф/api/carts/AddToCart?productId=${productId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (resp) {
        console.log(resp);
        setCartCount(resp.data);
      })
      .catch(function (error) {
        console.log(error);
        // if (error.response.status === 401) {
        //   alert("Для просмотра корзины необходимо войти в аккаунт.");
        // }
      });
  };

  const onSearchChange = useCallback(
    (word) => {
      console.log(word);

      if (word.length <= 2) {
        return;
      }

      setWords(word);

      axios
        .get(`https://апи.звеножизни.рф/api/pages/search?data=${word}`)
        .then((res) => {
          console.log(res);
          setProducts(res.data);
        })
        .catch((err) => {
          alert("Во время поиска что-то пошло не так.");
        });
    },
    [setWords]
  );

  return (
    <>
      <Header count={cartCount} />
      <div className="search">
        <input
          type={"text"}
          autoFocus
          className="search-input"
          onChange={(event) => onSearchChange(event.target.value)}
        />
        {isLoaded ? (
          products.map((disease) => {
            return (
              <div className="disease" key={disease.name}>
                <div className="disease-header">
                  <label>{disease.name}</label>
                </div>
                <div className="disease-body">
                  <img src={disease.imageURL} alt="" />
                  <label>
                    {/* Как это было: жил был и ничего даже не замечал и не предавал
                значения, занимаясь серебром, начал в себе искать места
                применения 😂😂😂, ну вот и нашёл зону для теста. В итоге
                настроился что буду «мазать» каждый день и даже не
                пропускать😂😂😂, с таким напором я так сказать запустил
                «мегатест» , и через призму шуточек на пятый день я понял что
                произошли непонятные «чудеса». На радостях потом сбегал на
                обработку стоп и с тех пор, по этой части у меня всё «чики-пуки» */}
                    {disease.description}
                  </label>
                </div>
                <div
                  className="disease-footer"
                  style={{ display: disease.price === 0 ? "none" : "flex" }}
                >
                  <div className="price">₽{disease.price}</div>
                  <div className="add-to-cart">
                    <label
                      htmlFor={"cartBtn"}
                      onClick={() => addToCart(disease.id)}
                    >
                      <ShoppingCartOutlinedIcon id={"cartBtn"} />
                    </label>
                    <label>в корзину</label>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="disease">
            <div className="disease-header">
              <label>
                <Skeleton variant="rounded" width={80} height={20} />
              </label>
            </div>
            <div className="disease-body">
              <Skeleton variant="rounded" width={"100%"} height={"20rem"} />
              <label>
                {/* Как это было: жил был и ничего даже не замечал и не предавал
          значения, занимаясь серебром, начал в себе искать места
          применения 😂😂😂, ну вот и нашёл зону для теста. В итоге
          настроился что буду «мазать» каждый день и даже не
          пропускать😂😂😂, с таким напором я так сказать запустил
          «мегатест» , и через призму шуточек на пятый день я понял что
          произошли непонятные «чудеса». На радостях потом сбегал на
          обработку стоп и с тех пор, по этой части у меня всё «чики-пуки» */}
                <Skeleton variant="rounded" width={"100%"} height={20} />
                <Skeleton variant="rounded" width={"100%"} height={20} />
              </label>
            </div>
            <div className="disease-footer" style={{ display: "flex" }}>
              <div className="price">
                <Skeleton variant="rounded" width={"100%"} height={20} />
              </div>
              {/* <div className="add-to-cart">Добавить в корзину</div>
               */}
              <div className="add-to-cart">
                <label htmlFor={"cartBtn"}>
                  <ShoppingCartOutlinedIcon id={"cartBtn"} />
                </label>
                <label>в корзину</label>
              </div>
            </div>
          </div>
        )}
        <Footer pageName="Search" />
      </div>
    </>
  );
}
