import React, { useState } from "react";
import "./SignIn.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import validator from "validator";

import { Modal, Box, Backdrop, Typography } from "@mui/material";
import Logo from "../../Files/Icons/logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import LogoImage from "../../Files/Images/logo.jpg";
const ModalStyle = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "white",
  boxShadow: 10,
  p: 3,
  lineHeight: "2",
};

export default function SignIn(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorData, setErrorData] = useState("");

  const signIn = () => {
    if (!validator.isEmail(email)) {
      return alert("Введен некорректный адрес эл. почты.");
    }

    if (password.length <= 5) {
      return alert("Длина пароля должна составлять 5 и более символов.");
    }

    setBackdropOpen(true);

    axios
      .post("https://апи.звеножизни.рф/api/auth/signin", {
        email: email,
        password: password,
      })
      .then(function (resp) {
        localStorage.setItem("token", resp.data.token);
        localStorage.setItem("username", resp.data.username);
        localStorage.setItem("userId", resp.data.userId);
        setBackdropOpen(false);
        navigate("/");
      })
      .catch(function (error) {
        setBackdropOpen(false);
        setErrorData(error.response.data);
        setModalOpen(!modalOpen);
      });
  };

  return (
    <div className="signin">
      <div className="signin-form">
        <span className="com-logo">
          <div
            className="company-logo"
            style={{ backgroundImage: `url(${Logo})` }}
          />
        </span>
        <label>Звено жизни</label>
        <input
          placeholder="Эл. почта"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          placeholder="Пароль"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <button onClick={() => signIn()}>Войти</button>
        <div className="signin-footer">
          <button>Забыли пароль?</button>

          <button onClick={() => navigate("/signup")}>Регистрация</button>
        </div>
      </div>
      {/* <div className="signin-footer">
            <label>У меня нет аккаунта?</label>
            <span onClick={() => navigate("/signup")}> Зарегистрируйтесь.</span>
        </div> */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "scroll",
        }}
      >
        <Typography variant="div">
          <Box sx={ModalStyle}>
            <Box style={{ margin: "auto", textAlign: "center" }}>
              {errorData}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <button
                className="btn-close"
                onClick={() => setModalOpen(!modalOpen)}
              >
                Закрыть
              </button>
            </Box>
          </Box>
        </Typography>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        onClick={() => setBackdropOpen(!backdropOpen)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
