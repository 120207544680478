import React, { useState, useEffect } from "react";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";

// import PlusIcon from "../../Files/Icons/plus-square.svg";
// import ListIcon from "../../Files/Icons/list.svg";
// import ShareIcon from "../../Files/Icons/share.svg";
// import BucketIcon from "../../Files/Icons/bucket.svg";

import Badge from "@mui/material/Badge";

export default function Header({ count }) {
  const navigate = useNavigate();

  const [auth, setAuth] = useState();
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ModalStyle = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "white",
    boxShadow: 10,
    p: 3,
    lineHeight: "2",
  };

  function AuthCheck() {
    if (isLoggedIn) {
      navigate("/cart");
    } else {
      handleOpen();
    }
  }
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token !== null) {
      setLoggedIn(true);
    }
  }, [setAuth, isLoggedIn]);

  return (
    <div className="header">
      <label onClick={() => navigate("/")}>Звено жизни</label>
      <label>
        <Badge badgeContent={count} color="primary">
          <label onClick={AuthCheck}>Корзина</label>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              overflow: "scroll",
            }}
          >
            <Typography variant="div">
              <Box sx={ModalStyle}>
                <Box style={{ margin: "auto" }}>
                  <label>
                    Чтобы воспользоваться корзиной необходимо{" "}
                    <u onClick={() => navigate("/signin")}>войти</u> в аккаунт
                    или{" "}
                    <u onClick={() => navigate("/signup")}>
                      зарегистрироваться
                    </u>
                  </label>
                </Box>
                <Box
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <button className="btn-color" onClick={handleClose}>
                    Хорошо
                  </button>
                </Box>
              </Box>
            </Typography>
          </Modal>

          {/* <img src={BucketIcon} alt="" /> */}
        </Badge>
      </label>
      {/* <span>
            <img src={ListIcon} alt="" />
        </span> */}
    </div>
  );
}
