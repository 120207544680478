import React, { useState, useEffect } from "react";
import "./Diseases.scss";

import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Catalog1 from "../../Files/Images/catalog_2.png";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import axios from "axios";

export default function Diseases() {
  const [diseases, setDiseases] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const { diseaseName } = useParams();

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token === null) return setLoaded(true);

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `https://апи.звеножизни.рф/api/pages/category-products?categoryId=${diseaseName}`
      )
      .then((response) => {
        setLoaded(true);
        setDiseases(response.data);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setLoaded(true);
      });
  }, [diseaseName]);

  const addToCart = (productId) => {
    let token = localStorage.getItem("token");

    if (token === null) {
      return alert(
        "Чтобы добавить товар в корзину необходимо пройти регистрацию или войти в аккаунт."
      );
    }

    axios
      .post(
        `https://апи.звеножизни.рф/api/carts/AddToCart?productId=${productId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (resp) {
        console.log(resp);
        setCartCount(resp.data);
      })
      .catch(function (error) {
        console.log(error);
        // if (error.response.status === 401) {
        //   alert("Для просмотра корзины необходимо войти в аккаунт.");
        // }
      });
  };

  return (
    <div className="diseases">
      <Header count={cartCount} />
      {isLoaded ? (
        diseases.map((disease) => {
          return (
            <div className="disease" key={disease.name}>
              <div className="disease-header">
                <label>{disease.name}</label>
              </div>
              <div className="disease-body">
                <img src={disease.imageURL} alt="" />
                <label>
                  {/* Как это было: жил был и ничего даже не замечал и не предавал
                значения, занимаясь серебром, начал в себе искать места
                применения 😂😂😂, ну вот и нашёл зону для теста. В итоге
                настроился что буду «мазать» каждый день и даже не
                пропускать😂😂😂, с таким напором я так сказать запустил
                «мегатест» , и через призму шуточек на пятый день я понял что
                произошли непонятные «чудеса». На радостях потом сбегал на
                обработку стоп и с тех пор, по этой части у меня всё «чики-пуки» */}
                  {disease.description}
                </label>
              </div>
              <div
                className="disease-footer"
                style={{ display: disease.price === 0 ? "none" : "flex" }}
              >
                <div className="price">₽{disease.price}</div>
                {/* <div className="add-to-cart">Добавить в корзину</div>
                 */}
                <div
                  className="add-to-cart"
                  onClick={() => addToCart(disease.id)}
                >
                  <label htmlFor={"cartBtn"}>
                    <ShoppingCartOutlinedIcon id={"cartBtn"} />
                  </label>
                  <label>в корзину</label>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="disease">
          <div className="disease-header">
            <label>
              <Skeleton variant="rounded" width={80} height={20} />
            </label>
          </div>
          <div className="disease-body">
            <Skeleton variant="rounded" width={"100%"} height={"20rem"} />
            <label>
              <Skeleton variant="rounded" width={"100%"} height={20} />
              <Skeleton variant="rounded" width={"100%"} height={20} />
            </label>
          </div>
          <div className="disease-footer" style={{ display: "flex" }}>
            <div className="price">
              <Skeleton variant="rounded" width={"100%"} height={20} />
            </div>
            {/* <div className="add-to-cart">Добавить в корзину</div>
             */}
            <div className="add-to-cart">
              <label htmlFor={"cartBtn"}>
                <ShoppingCartOutlinedIcon id={"cartBtn"} />
              </label>
              <label>в корзину</label>
            </div>
          </div>
        </div>
      )}
      <Footer pageName="Home" />
    </div>
  );
}
