import React, { useEffect, useState } from "react";
import "./Profile.scss";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { ReactComponent as RightArrowIcon } from "../../Files/Icons/right-arrow.svg";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 3,
  overflowY: "scroll",
  borderRadius: "10px",
};

export default function Profile() {
  //условия возврата
  const [returnConditionsVisibility, setReturnConditionsVisibility] =
    useState(false);

  //порядок оплаты
  const [paymentOrderVisibility, setPaymentOrderVisibility] = useState(false);

  //реквизиты
  const [requisitesVisibility, setRequisitesVisibility] = useState(false);

  //Политика конфиденциальности
  const [privacyPolicyVisibility, setPrivacyPolicyVisibility] = useState(false);

  const [deliveryTermsVisibility, setDeliveryTermsVisibility] = useState(false);

  const navigate = useNavigate();

  const [username, setUsername] = useState("");

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let un = localStorage.getItem("username");
    setUsername(un);

    let token = localStorage.getItem("token");

    if (token === null) return;

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [username, setCartCount]);

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const openLinkInNewTab = (url) => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };

  return (
    <>
      <Header count={cartCount} />
      <div className="profile">
        <div className="profile-content">
          <div className="sections">
            <label className="category-header">доставка</label>
            <div
              className="profile-section"
              onClick={() => setDeliveryTermsVisibility(true)}
            >
              <label>Условия доставки</label>
              <RightArrowIcon />
            </div>
            <label className="category-header">покупки</label>
            <div className="profile-section" onClick={() => navigate("orders")}>
              <label>История заказов</label>
              <RightArrowIcon />
            </div>
            <label className="category-header">Популярные вопросы</label>
            <div
              onClick={() => setPaymentOrderVisibility(true)}
              className="profile-section"
            >
              <label>Порядок оплаты</label>
              <RightArrowIcon />
            </div>
            <div
              onClick={() => setReturnConditionsVisibility(true)}
              className="profile-section"
            >
              <label>Условия возврата</label>
              <RightArrowIcon />
            </div>
            <div
              onClick={() => setRequisitesVisibility(true)}
              className="profile-section"
            >
              <label>Реквизиты</label>
              <RightArrowIcon />
            </div>

            <label className="category-header">веб-сайт</label>
            <div
              onClick={() => setPrivacyPolicyVisibility(true)}
              className="profile-section"
            >
              <label>Политика конфиденциальности</label>
              <RightArrowIcon />
            </div>
            <div
              onClick={() =>
                openLinkInNewTab(
                  "https://docs.google.com/document/d/e/2PACX-1vQrb1X8AtqStEYsUymglANhnmucNqBDHJetlctdQjW7ltyQuF0cvD5kqraZ156mTM1mCWQBZ3EMeoV1/pub"
                )
              }
              className="profile-section"
            >
              <label>Пользовательское соглашение</label>
              <RightArrowIcon />
            </div>
            <div
              onClick={() =>
                window.open(
                  "https://docs.google.com/document/d/e/2PACX-1vSbwBWq-HEDZ2t0PUuh4BxGxnERSpwREnC6U4ygOwd_Yr9cDpuIGDtJDEfU6iwhlLvxYFlOXtVO6FON/pub",
                  "_blank"
                )
              }
              className="profile-section"
            >
              <label>Договор оферта</label>
              <RightArrowIcon />
            </div>
            <div className="profile-section" onClick={() => logout()}>
              <label>Выйти из аккаунта</label>
              <RightArrowIcon />
            </div>
          </div>
        </div>
        <Footer pageName="Profile" />
      </div>
      {/* Условия возврата */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={returnConditionsVisibility}
        onClose={() => setReturnConditionsVisibility(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={returnConditionsVisibility}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Условия возврата
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Возврат денежных средств производится согласно условиям договора.
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Заявление необходимо написать на имя генерального директора
                ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ТОРГОВЫЙ ДОМ
                «ГРАДАЛИС»
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Урлагова Александра Андреевича, подписать собственноручно и
                прислать по почте nanococktel@mail.ru.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                В заявлении на возврат в обязательном порядке укажите:
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - ФИО
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Паспортные данные (серия номер, кем и когда выдан, дата
                рождения)
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Адрес
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Логин аккаунта (если есть)
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Сумма возврата.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Причина возврата
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Ваш ИНН
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Реквизиты вашего банка (наименование, адрес, БИК)
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Номер карты с которой производилась оплата товара.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Номер расчётного счёта в указанном банке.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Контактный номер телефона и Email.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Все реквизиты банка и расчётного счёта можно найти в мобильным
                приложении, личном кабинете вашего банка или получить при личном
                посещении финансового учреждения.Основанием в отказе возврата
                средств могут являться следующие причины:
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Недоступный или несуществующий контактный номер
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Неверные реквизиты или логин
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Возврат несуществующих денежных средств
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Неверно заполненное заявление на возврат
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Заявление в котором указаны не все требуемые параметры.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Заявление заполнено/подано третьим лицом без должной формы
                представительства.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                - Заявление нечитабельно.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Срок рассмотрения заявления составляет от 14 до 30 дней.
              </Typography>
            </Typography>
            <Button
              onClick={() => setReturnConditionsVisibility(false)}
              sx={{ width: "100%", margin: "1rem 0" }}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>
      {/* Порядок оплаты */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={paymentOrderVisibility}
        onClose={() => setPaymentOrderVisibility(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={paymentOrderVisibility}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Порядок оплаты
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Сайт Звено Жизни (ООО "ТД "ГРАДАЛИС" далее - Звено Жизни)
              подключен к онлайн-эквайрингу и принимает онлайн-оплату за товары
              представленные в его каталоге. Вы можете выбрать любой товар и
              оплатить его действующей банковской картой при условии
              положительного баланса на ней.
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Звено Жизни принимает к оплате банковские карты: VISA,
                MasterCard, МИР, China UnionPay, JCB и American Express;
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                VISA Electron/Plus, Cirrus/Maestro, если у них есть код CVC2 и
                CVV2;любых банков, а также оплату баллами.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Банковская карта.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                1. Для оформления и онлайн оплаты заказа, выберите в каталоге
                продукции Звено Жизни товары и добавьте их в корзину.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                2. Далее в корзине ознакомьтесь с итоговой суммой для оплаты
                товара и нажмите “Оплатить”.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                3. После подтверждения выбранного Товара откроется защищенное
                окно с платежной страницей эквайера Tinkoff, где Вам необходимо
                ввести данные Вашей банковской карты. Для дополнительной
                аутентификации держателя карты используется протокол 3D Secure.
                Если Ваш Банк поддерживает данную технологию, Вы будете
                перенаправлены на его сервер для дополнительной идентификации.
                Информацию о правилах и методах дополнительной идентификации
                уточняйте в Банке, выдавшем Вам банковскую карту.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                4. После оплаты корзины вы получите электронный чек. Все
                электронные чеки можно скачать в личном кабинете в разделе
                “История покупок”.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Банковский перевод на расчетный счёт.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Банковский перевод возможен по счёту на оплату и производится по
                реквизитам указанным в разделе реквизитов.
                <span
                  className="outlined"
                  onClick={() => setRequisitesVisibility(true)}
                >
                  Перейти к реквизитам организации.
                </span>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Прием онлайн платежей осуществляется посредством эквайринга от
                Тинькофф, гарантирующем безопасность и сохранность
                представленных вами банковских реквизитов, которые не подлежат
                разглашению. Звено Жизни не хранит данные ваших банковских карт
                и иные банковские реквизиты вводимые вами при оплате. В конце
                текста разместить логотипы МИР, VISA, MasterCard, Tinkoff
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
              ></Typography>
            </Typography>
            <Button
              onClick={() => setPaymentOrderVisibility(false)}
              sx={{ width: "100%", margin: "1rem 0" }}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>
      {/* Реквизиты */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={requisitesVisibility}
        onClose={() => setRequisitesVisibility(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={requisitesVisibility}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Реквизиты
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Полное наименование ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
              "ТОРГОВЫЙ ДОМ "ГРАДАЛИС"
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Сокращенное наименование ООО "ТД "ГРАДАЛИС"
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                ОГРН 1211600070417
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                ИНН 1650406360
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                КПП 165001001
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Юридический адрес: 423823, Республика Татарстан, г. Набережные
                Челны, проспект Сююмбике, д. 40, помещение 54,55
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Генеральный директор: Урлагов Александр Андреевич
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Банк Поволжский филиал АО «Райффайзенбанк»
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                БИК 042202847
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                К/С 30101810300000000847
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Р/С 40702810223000090292
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Тел. +7 (927) 0457575
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Эл. почта nanococktel@mail.ru
              </Typography>
            </Typography>

            <Button
              onClick={() => setRequisitesVisibility(false)}
              sx={{ width: "100%", margin: "1rem 0" }}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>
      {/* Политика конфиденциальности */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={privacyPolicyVisibility}
        onClose={() => setPrivacyPolicyVisibility(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={privacyPolicyVisibility}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Политика конфиденциальности
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Политика в отношении обработки персональных данных ООО «Торговый
              Дом «ГРАДАЛИС»
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              1. Общие положения
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Настоящая политика обработки персональных данных составлена в
              соответствии с требованиями Федерального закона от 27.07.2006.
              №152-ФЗ «О персональных данных» (далее - Закон о персональных
              данных) и определяет порядок обработки персональных данных и меры
              по обеспечению безопасности персональных данных, предпринимаемые
              ООО «ТОРГОВЫЙ ДОМ «ГРАДАЛИС» (далее – Оператор).
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              1.1. Оператор ставит своей важнейшей целью и условием
              осуществления своей деятельности соблюдение прав и свобод человека
              и гражданина при обработке его персональных данных, в том числе
              защиты прав на неприкосновенность частной жизни, личную и семейную
              тайну.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              1.2. Настоящая политика Оператора в отношении обработки
              персональных данных (далее – Политика) применяется ко всей
              информации, которую Оператор может получить о посетителях
              веб-сайта https://звеножизни.рф.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2. Основные понятия, используемые в Политике
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.1. Автоматизированная обработка персональных данных – обработка
              персональных данных с помощью средств вычислительной техники.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.2. Блокирование персональных данных – временное прекращение
              обработки персональных данных (за исключением случаев, если
              обработка необходима для уточнения персональных данных).
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.3. Веб-сайт – совокупность графических и информационных
              материалов, а также программ для ЭВМ и баз данных, обеспечивающих
              их доступность в сети интернет по сетевому адресу
              https://звеножизни.рф.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.4. Информационная система персональных данных — совокупность
              содержащихся в базах данных персональных данных, и обеспечивающих
              их обработку информационных технологий и технических средств.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.5. Обезличивание персональных данных — действия, в результате
              которых невозможно определить без использования дополнительной
              информации принадлежность персональных данных конкретному
              Пользователю или иному субъекту персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.6. Обработка персональных данных – любое действие (операция) или
              совокупность действий (операций), совершаемых с использованием
              средств автоматизации или без использования таких средств с
              персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.7. Оператор – государственный орган, муниципальный орган,
              юридическое или физическое лицо, самостоятельно или совместно с
              другими лицами организующие и (или) осуществляющие обработку
              персональных данных, а также определяющие цели обработки
              персональных данных, состав персональных данных, подлежащих
              обработке, действия (операции), совершаемые с персональными
              данными.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.8. Персональные данные – любая информация, относящаяся прямо или
              косвенно к определенному или определяемому Пользователю веб-сайта
              https://звеножизни.рф.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.9. Персональные данные, разрешенные субъектом персональных
              данных для распространения, - персональные данные, доступ
              неограниченного круга лиц к которым предоставлен субъектом
              персональных данных путем дачи согласия на обработку персональных
              данных, разрешенных субъектом персональных данных для
              распространения в порядке, предусмотренном Законом о персональных
              данных (далее - персональные данные, разрешенные для
              распространения).
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.10. Пользователь – любой посетитель веб-сайта
              https://звеножизни.рф.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.11. Предоставление персональных данных – действия, направленные
              на раскрытие персональных данных определенному лицу или
              определенному кругу лиц.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.12. Распространение персональных данных – любые действия,
              направленные на раскрытие персональных данных неопределенному
              кругу лиц (передача персональных данных) или на ознакомление с
              персональными данными неограниченного круга лиц, в том числе
              обнародование персональных данных в средствах массовой информации,
              размещение в информационно-телекоммуникационных сетях или
              предоставление доступа к персональным данным каким-либо иным
              способом.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.13. Трансграничная передача персональных данных – передача
              персональных данных на территорию иностранного государства органу
              власти иностранного государства, иностранному физическому или
              иностранному юридическому лицу.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              2.14. Уничтожение персональных данных – любые действия, в
              результате которых персональные данные уничтожаются безвозвратно с
              невозможностью дальнейшего восстановления содержания персональных
              данных в информационной системе персональных данных и (или)
              уничтожаются материальные носители персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              3. Основные права и обязанности Оператора
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              3.1. Оператор имеет право:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – получать от субъекта персональных данных достоверные информацию
              и/или документы, содержащие персональные данные;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – в случае отзыва субъектом персональных данных согласия на
              обработку персональных данных Оператор вправе продолжить обработку
              персональных данных без согласия субъекта персональных данных при
              наличии оснований, указанных в Законе о персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – самостоятельно определять состав и перечень мер, необходимых и
              достаточных для обеспечения выполнения обязанностей,
              предусмотренных Законом о персональных данных и принятыми в
              соответствии с ним нормативными правовыми актами, если иное не
              предусмотрено Законом о персональных данных или другими
              федеральными законами.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              3.2. Оператор обязан:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – предоставлять субъекту персональных данных по его просьбе
              информацию, касающуюся обработки его персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – организовывать обработку персональных данных в порядке,
              установленном действующим законодательством РФ;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – отвечать на обращения и запросы субъектов персональных данных и
              их законных представителей в соответствии с требованиями Закона о
              персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – сообщать в уполномоченный орган по защите прав субъектов
              персональных данных по запросу этого органа необходимую информацию
              в течение 30 дней с даты получения такого запроса;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – публиковать или иным образом обеспечивать неограниченный доступ
              к настоящей Политике в отношении обработки персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – принимать правовые, организационные и технические меры для
              защиты персональных данных от неправомерного или случайного
              доступа к ним, уничтожения, изменения, блокирования, копирования,
              предоставления, распространения персональных данных, а также от
              иных неправомерных действий в отношении персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – прекратить передачу (распространение, предоставление, доступ)
              персональных данных, прекратить обработку и уничтожить
              персональные данные в порядке и случаях, предусмотренных Законом о
              персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – исполнять иные обязанности, предусмотренные Законом о
              персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              4. Основные права и обязанности субъектов персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              4.1. Субъекты персональных данных имеют право:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – получать информацию, касающуюся обработки его персональных
              данных, за исключением случаев, предусмотренных федеральными
              законами. Сведения предоставляются субъекту персональных данных
              Оператором в доступной форме, и в них не должны содержаться
              персональные данные, относящиеся к другим субъектам персональных
              данных, за исключением случаев, когда имеются законные основания
              для раскрытия таких персональных данных. Перечень информации и
              порядок ее получения установлен Законом о персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – требовать от оператора уточнения его персональных данных, их
              блокирования или уничтожения в случае, если персональные данные
              являются неполными, устаревшими, неточными, незаконно полученными
              или не являются необходимыми для заявленной цели обработки, а
              также принимать предусмотренные законом меры по защите своих прав;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – выдвигать условие предварительного согласия при обработке
              персональных данных в целях продвижения на рынке товаров, работ и
              услуг;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – на отзыв согласия на обработку персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – обжаловать в уполномоченный орган по защите прав субъектов
              персональных данных или в судебном порядке неправомерные действия
              или бездействие Оператора при обработке его персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – на осуществление иных прав, предусмотренных законодательством
              РФ.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              4.2. Субъекты персональных данных обязаны:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – предоставлять Оператору достоверные данные о себе;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – сообщать Оператору об уточнении (обновлении, изменении) своих
              персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              4.3. Лица, передавшие Оператору недостоверные сведения о себе,
              либо сведения о другом субъекте персональных данных без согласия
              последнего, несут ответственность в соответствии с
              законодательством РФ.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5. Оператор может обрабатывать следующие персональные данные
              Пользователя
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.1. Фамилия, имя, отчество.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.2. Электронный адрес.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.3. Номера телефонов.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.4. Фотографии.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.5. Реквизиты документа, удостоверяющего личность.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.6. Адрес фактического места проживания и регистрации по месту
              жительства и (или) по месту пребывания.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.7. Также на сайте происходит сбор и обработка обезличенных
              данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
              интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.8. Вышеперечисленные данные далее по тексту Политики объединены
              общим понятием Персональные данные.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.9. Обработка специальных категорий персональных данных,
              касающихся расовой, национальной принадлежности, политических
              взглядов, религиозных или философских убеждений, интимной жизни,
              Оператором не осуществляется.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.10. Обработка персональных данных, разрешенных для
              распространения, из числа специальных категорий персональных
              данных, указанных в ч. 1 ст. 10 Закона о персональных данных,
              допускается, если соблюдаются запреты и условия, предусмотренные
              ст. 10.1 Закона о персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.11. Согласие Пользователя на обработку персональных данных,
              разрешенных для распространения, оформляется отдельно от других
              согласий на обработку его персональных данных. При этом
              соблюдаются условия, предусмотренные, в частности, ст. 10.1 Закона
              о персональных данных. Требования к содержанию такого согласия
              устанавливаются уполномоченным органом по защите прав субъектов
              персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.11.1 Согласие на обработку персональных данных, разрешенных для
              распространения, Пользователь предоставляет Оператору
              непосредственно.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.11.2 Оператор обязан в срок не позднее трех рабочих дней с
              момента получения указанного согласия Пользователя опубликовать
              информацию об условиях обработки, о наличии запретов и условий на
              обработку неограниченным кругом лиц персональных данных,
              разрешенных для распространения.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.11.3 Передача (распространение, предоставление, доступ)
              персональных данных, разрешенных субъектом персональных данных для
              распространения, должна быть прекращена в любое время по
              требованию субъекта персональных данных. Данное требование должно
              включать в себя фамилию, имя, отчество (при наличии), контактную
              информацию (номер телефона, адрес электронной почты или почтовый
              адрес) субъекта персональных данных, а также перечень персональных
              данных, обработка которых подлежит прекращению. Указанные в данном
              требовании персональные данные могут обрабатываться только
              Оператором, которому оно направлено.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              5.11.4 Согласие на обработку персональных данных, разрешенных для
              распространения, прекращает свое действие с момента поступления
              Оператору требования, указанного в п. 5.11.3 настоящей Политики в
              отношении обработки персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6. Принципы обработки персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.1. Обработка персональных данных осуществляется на законной и
              справедливой основе.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.2. Обработка персональных данных ограничивается достижением
              конкретных, заранее определенных и законных целей. Не допускается
              обработка персональных данных, несовместимая с целями сбора
              персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.3. Не допускается объединение баз данных, содержащих
              персональные данные, обработка которых осуществляется в целях,
              несовместимых между собой.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.4. Обработке подлежат только персональные данные, которые
              отвечают целям их обработки.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.5. Содержание и объем обрабатываемых персональных данных
              соответствуют заявленным целям обработки. Не допускается
              избыточность обрабатываемых персональных данных по отношению к
              заявленным целям их обработки.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.6. При обработке персональных данных обеспечивается точность
              персональных данных, их достаточность, а в необходимых случаях и
              актуальность по отношению к целям обработки персональных данных.
              Оператор принимает необходимые меры и/или обеспечивает их принятие
              по удалению или уточнению неполных или неточных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              6.7. Хранение персональных данных осуществляется в форме,
              позволяющей определить субъекта персональных данных, не дольше,
              чем этого требуют цели обработки персональных данных, если срок
              хранения персональных данных не установлен федеральным законом,
              договором, стороной которого, выгодоприобретателем или поручителем
              по которому является субъект персональных данных. Обрабатываемые
              персональные данные уничтожаются либо обезличиваются по достижении
              целей обработки или в случае утраты необходимости в достижении
              этих целей, если иное не предусмотрено федеральным законом.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              7. Цели обработки персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              7.1. Цель обработки персональных данных Пользователя:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – информирование Пользователя посредством отправки электронных
              писем;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – заключение, исполнение и прекращение гражданско-правовых
              договоров;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – предоставление доступа Пользователю к сервисам, информации и/или
              материалам, содержащимся на веб-сайте https://звеножизни.рф;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – предоставление услуг.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              7.2. Также Оператор имеет право направлять Пользователю
              уведомления о новых продуктах и услугах, специальных предложениях
              и различных событиях. Пользователь всегда может отказаться от
              получения информационных сообщений, направив Оператору письмо на
              адрес электронной почты nanococktel@mail.ru с пометкой «Отказ от
              уведомлений о новых продуктах и услугах и специальных
              предложениях».
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              7.3. Обезличенные данные Пользователей, собираемые с помощью
              сервисов интернет-статистики, служат для сбора информации о
              действиях Пользователей на сайте, улучшения качества сайта и его
              содержания.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              8. Правовые основания обработки персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              8.1. Правовыми основаниями обработки персональных данных
              Оператором являются:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – перечислите нормативно-правовые акты, регулирующие отношения,
              связанные с вашей деятельностью, например, если ваша деятельность
              связана с информационными технологиями, в частности с созданием
              сайтов, то здесь можно указать Федеральный закон "Об информации,
              информационных технологиях и о защите информации" от 27.07.2006 N
              149-ФЗ;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – уставные документы Оператора;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – договоры, заключаемые между оператором и субъектом персональных
              данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – федеральные законы, иные нормативно-правовые акты в сфере защиты
              персональных данных;
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              – согласия Пользователей на обработку их персональных данных, на
              обработку персональных данных, разрешенных для распространения.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              8.2. Оператор обрабатывает персональные данные Пользователя только
              в случае их заполнения и/или отправки Пользователем самостоятельно
              через специальные формы, расположенные на сайте
              https://звеножизни.рф или направленные Оператору посредством
              электронной почты. Заполняя соответствующие формы и/или отправляя
              свои персональные данные Оператору, Пользователь выражает свое
              согласие с данной Политикой.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              8.3. Оператор обрабатывает обезличенные данные о Пользователе в
              случае, если это разрешено в настройках браузера Пользователя
              (включено сохранение файлов «cookie» и использование технологии
              JavaScript).
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              8.4. Субъект персональных данных самостоятельно принимает решение
              о предоставлении его персональных данных и дает согласие свободно,
              своей волей и в своем интересе.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9. Условия обработки персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.1. Обработка персональных данных осуществляется с согласия
              субъекта персональных данных на обработку его персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.2. Обработка персональных данных необходима для достижения
              целей, предусмотренных международным договором Российской
              Федерации или законом, для осуществления возложенных
              законодательством Российской Федерации на оператора функций,
              полномочий и обязанностей.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.3. Обработка персональных данных необходима для осуществления
              правосудия, исполнения судебного акта, акта другого органа или
              должностного лица, подлежащих исполнению в соответствии с
              законодательством Российской Федерации об исполнительном
              производстве.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.4. Обработка персональных данных необходима для исполнения
              договора, стороной которого либо выгодоприобретателем или
              поручителем по которому является субъект персональных данных, а
              также для заключения договора по инициативе субъекта персональных
              данных или договора, по которому субъект персональных данных будет
              являться выгодоприобретателем или поручителем.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.5. Обработка персональных данных необходима для осуществления
              прав и законных интересов оператора или третьих лиц либо для
              достижения общественно значимых целей при условии, что при этом не
              нарушаются права и свободы субъекта персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.6. Осуществляется обработка персональных данных, доступ
              неограниченного круга лиц к которым предоставлен субъектом
              персональных данных либо по его просьбе (далее – общедоступные
              персональные данные).
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              9.7. Осуществляется обработка персональных данных, подлежащих
              опубликованию или обязательному раскрытию в соответствии с
              федеральным законом.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10. Порядок сбора, хранения, передачи и других видов обработки
              персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Безопасность персональных данных, которые обрабатываются
              Оператором, обеспечивается путем реализации правовых,
              организационных и технических мер, необходимых для выполнения в
              полном объеме требований действующего законодательства в области
              защиты персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.1. Оператор обеспечивает сохранность персональных данных и
              принимает все возможные меры, исключающие доступ к персональным
              данным неуполномоченных лиц.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.2. Персональные данные Пользователя никогда, ни при каких
              условиях не будут переданы третьим лицам, за исключением случаев,
              связанных с исполнением действующего законодательства либо в
              случае, если субъектом персональных данных дано согласие Оператору
              на передачу данных третьему лицу для исполнения обязательств по
              гражданско-правовому договору.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.3. В случае выявления неточностей в персональных данных,
              Пользователь может актуализировать их самостоятельно, путем
              направления Оператору уведомление на адрес электронной почты
              Оператора nanococktel@mail.ru с пометкой «Актуализация
              персональных данных».
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.4. Срок обработки персональных данных определяется достижением
              целей, для которых были собраны персональные данные, если иной
              срок не предусмотрен договором или действующим законодательством.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Пользователь может в любой момент отозвать свое согласие на
              обработку персональных данных, направив Оператору уведомление
              посредством электронной почты на электронный адрес Оператора
              nanococktel@mail.ru с пометкой «Отзыв согласия на обработку
              персональных данных».
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.5. Вся информация, которая собирается сторонними сервисами, в
              том числе платежными системами, средствами связи и другими
              поставщиками услуг, хранится и обрабатывается указанными лицами
              (Операторами) в соответствии с их Пользовательским соглашением и
              Политикой конфиденциальности. Субъект персональных данных и/или
              Пользователь обязан самостоятельно своевременно ознакомиться с
              указанными документами. Оператор не несет ответственность за
              действия третьих лиц, в том числе указанных в настоящем пункте
              поставщиков услуг.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.6. Установленные субъектом персональных данных запреты на
              передачу (кроме предоставления доступа), а также на обработку или
              условия обработки (кроме получения доступа) персональных данных,
              разрешенных для распространения, не действуют в случаях обработки
              персональных данных в государственных, общественных и иных
              публичных интересах, определенных законодательством РФ.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.7. Оператор при обработке персональных данных обеспечивает
              конфиденциальность персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.8. Оператор осуществляет хранение персональных данных в форме,
              позволяющей определить субъекта персональных данных, не дольше,
              чем этого требуют цели обработки персональных данных, если срок
              хранения персональных данных не установлен федеральным законом,
              договором, стороной которого, выгодоприобретателем или поручителем
              по которому является субъект персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              10.9. Условием прекращения обработки персональных данных может
              являться достижение целей обработки персональных данных, истечение
              срока действия согласия субъекта персональных данных или отзыв
              согласия субъектом персональных данных, а также выявление
              неправомерной обработки персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              11. Перечень действий, производимых Оператором с полученными
              персональными данными
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              11.1. Оператор осуществляет сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление и
              уничтожение персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              11.2. Оператор осуществляет автоматизированную обработку
              персональных данных с получением и/или передачей полученной
              информации по информационно-телекоммуникационным сетям или без
              таковой.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              12. Трансграничная передача персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              12.1. Оператор до начала осуществления трансграничной передачи
              персональных данных обязан убедиться в том, что иностранным
              государством, на территорию которого предполагается осуществлять
              передачу персональных данных, обеспечивается надежная защита прав
              субъектов персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              12.2. Трансграничная передача персональных данных на территории
              иностранных государств, не отвечающих вышеуказанным требованиям,
              может осуществляться только в случае наличия согласия в письменной
              форме субъекта персональных данных на трансграничную передачу его
              персональных данных и/или исполнения договора, стороной которого
              является субъект персональных данных.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              13. Конфиденциальность персональных данных
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Оператор и иные лица, получившие доступ к персональным данным,
              обязаны не раскрывать третьим лицам и не распространять
              персональные данные без согласия субъекта персональных данных,
              если иное не предусмотрено федеральным законом.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              14. Заключительные положения
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              14.1. Пользователь может получить любые разъяснения по
              интересующим вопросам, касающимся обработки его персональных
              данных, обратившись к Оператору с помощью электронной почты
              nanococktel@mail.ru.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              14.2. В данном документе будут отражены любые изменения политики
              обработки персональных данных Оператором. Политика действует
              бессрочно до замены ее новой версией.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              14.3. Актуальная версия Политики в свободном доступе расположена в
              сети Интернет по адресу https://звеножизни.рф/privacy.
            </Typography>

            <Button
              onClick={() => setPrivacyPolicyVisibility(false)}
              sx={{ width: "100%", margin: "1rem 0" }}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Условия доставки */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deliveryTermsVisibility}
        onClose={() => setDeliveryTermsVisibility(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deliveryTermsVisibility}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h3">
              Условия доставки
            </Typography>
            <hr />
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Мы рады предложить вам удобный и надежный способ доставки
              продукции через курьерскую службу DPD. Пожалуйста, ознакомьтесь с
              условиями доставки.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Стоимость доставки:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Заказ продукции «ЗвеноЖизни.РФ» возможен только на сумму более
              10000₽. При заказе на сумму 10000₽ и выше доставка осуществляется
              бесплатно.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Сроки доставки:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Обычно заказ обрабатывается и готовится к отправке в течение 1-2
              рабочих дней после подтверждения заказа. Срок доставки может
              варьироваться в зависимости от вашего местоположения. В среднем,
              срок доставки составляет от 3 до 7 рабочих дней.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Процесс доставки:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Ваш заказ будет упакован с заботой и профессионализмом нашими
              специалистами. После отправки вам будет предоставлен
              трекинг-номер, который позволит вам отслеживать перемещение вашего
              заказа на сайте DPD. Курьер DPD свяжется с вами для согласования
              удобного времени доставки. В день доставки курьер своевременно
              доставит ваш заказ по указанному адресу или сообщит адрес пункта
              выдачи.
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              Правила и рекомендации:
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h3"
            >
              При получении заказа, пожалуйста, проверьте целостность упаковки и
              содержимое перед подписанием курьерской накладной. Если вы
              обнаружили повреждение упаковки или недостатки в продукции,
              пожалуйста, свяжитесь с нашей службой поддержки в течение 24 часов
              после получения заказа.
            </Typography>
            <Button
              onClick={() => setDeliveryTermsVisibility(false)}
              sx={{ width: "100%", margin: "1rem 0" }}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
