import React from "react";
import "./ProductInfo.scss";

import { useParams } from "react-router-dom";

export default function ProductInfo(props) {
  const { id } = useParams();

  return (
    <div className="product-info">
      <div className="image">
        <img
          src="https://gate.tothemoney.ru/api/v1/image/500/500/HMXkXGkpRxvgWT2Cu.png"
          alt=""
        />
      </div>
      <div className="content">
        <label className="name">Nano box</label>
        <div className="tags">
          <label>Минералка</label>
          <label>Вода</label>
          <label>Водка</label>
        </div>
        <label className="description">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
          eligendi natus perferendis eos corrupti dolore optio dolorem ipsam
          omnis laudantium est reiciendis sit similique, hic voluptatem
          molestias dignissimos excepturi sequi.
        </label>
      </div>
    </div>
  );
}
