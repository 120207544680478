import React, { useState, useEffect } from "react";
import "./Home.scss";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";

// icons for .visual-constrols
import { ReactComponent as GridIcon } from "../../Files/Icons/grid-3x3.svg";
import { ReactComponent as BoxIcon } from "../../Files/Icons/box.svg";
import { ReactComponent as PlayIcon } from "../../Files/Icons/play.svg";
import { ReactComponent as PersonIcon } from "../../Files/Icons/person-square.svg";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import axios from "axios";

import Stories from "react-insta-stories";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import LogoImage from "../../Files/Images/logo.jpg";

import VirusesBg from "../../Files/Images/viruses.png";
import HeartBg from "../../Files/Images/serdechno.png";
import ImmunityBg from "../../Files/Images/imunitet.png";
import AllergyBg from "../../Files/Images/allergy.png";
import DyhanieBg from "../../Files/Images/dyhanie.png";
import MochepolovyayaBg from "../../Files/Images/mochepolovya.png";
import PyshevarenieBg from "../../Files/Images/pyshevarenie.png";
import SystavyBg from "../../Files/Images/systavy.png";
import NarusheniaBg from "../../Files/Images/Narushenia.png";
import OjogiBg from "../../Files/Images/Ojogi.png";
import OrgansBg from "../../Files/Images/Organs.png";
import RasstroystvaBg from "../../Files/Images/Rasstroystva.png";

import { useMotionValueEvent } from "framer-motion";
import { motion, useMotionValue } from "framer-motion";
import { useTransform } from "framer-motion";
import Alert from "../../Components/Alert/Alert";

export default function Home(props) {
  const navigate = useNavigate();

  const [stories, setStories] = useState([
    "https://sun9-north.userapi.com/sun9-88/s/v1/ig2/E-gjIWSQjBsNxevwsPuK7_Lvhb7HhypnQShWBJ5Sq2toOObAol8DGfk2XLqG40gQHsc2clo6BHbFQ706Ozxbmpbm.jpg?size=820x960&quality=96&type=album",
    "https://sun9-west.userapi.com/sun9-62/s/v1/ig2/lAZ4JDf0rbB8A6yFsDmtZ2VQmDG7zJMhCJ_RTKrILgJJqmbf_F2VByj0zY5xf2IWKZxfRGo_PlUQTGyt1t0Kg0ca.jpg?size=801x960&quality=96&type=album",
    "https://sun9-west.userapi.com/sun9-16/s/v1/ig2/s1CakVQK0TtQdco-yV_ir1yAjBZjXroUCqqn6ognxW8oOwxlel5ymbh0kpzIGXr-NAbMvjxtd79g74Oie5yxK0QF.jpg?size=720x917&quality=96&type=album",
  ]);

  const [categories, setCategories] = useState([]);

  const [highlights, setHighlights] = useState([]);

  const [storiesOpen, setStoriesOpen] = useState(false);

  const [cartCount, setCartCount] = useState(0);

  const [isLoggedIn, setLoggedIn] = useState(false);

  const [actualStories, setActualStories] = useState([]);

  const [alertData, setAlertData] = useState({
    open: false,
    title: "",
    content: "",
    handleClose: () => setAlertData((prev) => ({ ...prev, open: false })),
  });

  useEffect(() => {
    let token = localStorage.getItem("token");

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
        // console.error(response.data.points);
        // console.log("RESP => ", response);
        setLoggedIn(true);
      })
      .catch(function (error) {
        console.log(error.response.data);
      });

    axios
      .get("https://апи.звеножизни.рф/api/pages/home")
      .then((response) => {
        setCategories(response.data.categories);
        console.log("HIGHTLIGHTS", response.data.highlights);
        setHighlights(response.data.highlights);
      })
      .catch((error) => console.log(error.response.data));
  }, []);

  const handleOpenStories = () => setStoriesOpen(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    boxShadow: 24,
  };

  const StoriesComponent = () => {
    return (
      <Stories
        stories={stories}
        defaultInterval={10000}
        width={432}
        height={768}
      />
    );
  };

  const mainBtn = () => {
    if (isLoggedIn) {
      return (
        <div className="reg-btn">
          <div
            className="registration-btn"
            onClick={() =>
              setAlertData((prev) => ({
                ...prev,
                open: true,
                title: "Контакты технической поддержки",
                content: (
                  <>
                    Для технической поддержки позвоните по номеру: <br /> +7 937
                    287 77 87 <br /> Мы будем звонить с номера: <br /> +7 995
                    007 77 88
                  </>
                ),
              }))
            }
          >
            <label>ТЕХНИЧЕСКАЯ ПОДДЕРЖКА</label>
            {/* <label>заказать обратный звонок</label> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="reg-btn">
          <div className="registration-btn" onClick={() => navigate("/signup")}>
            <label>Зарегистрироваться</label>
          </div>
          <div className="registration-btn" onClick={() => navigate("/signin")}>
            <label>Войти</label>
          </div>
        </div>
      );
    }
  };

  const OpenStories = (images) => {
    if (images.length === 0) {
      return;
    }

    let imgs = [];

    console.info("IMAGES", images);

    images.forEach((i) => {
      if (typeof i !== "undefined") {
        imgs.push(i.url);
      }
    });

    console.log(imgs);

    setActualStories(imgs);

    setStoriesOpen(true);
  };

  const y = useMotionValue(0);
  const input = [0, 200];
  const output = [1, 0];
  const opacity = useTransform(y, input, output);

  useMotionValueEvent(y, "animationStart", () => {
    console.log("animation started on y");
    console.log(y);
    setStoriesOpen(false);
  });

  return (
    <>
      <Header count={cartCount} />
      <div className="home">
        <div className="body">
          <div className="content-group">
            <div
              className="company-logo"
              style={{ backgroundImage: `url(${LogoImage})` }}
            />
            <div className="company-info" onClick={() => navigate("/points")}>
              Баллы
            </div>
            <div className="company-info">Корзина</div>
            <div className="company-info" onClick={() => navigate("/catalog")}>
              Каталог
            </div>
          </div>
          <div className="content">
            <label>
              Чтобы найти настоящие истории, можно воспользоваться
              <b>
                {" "}
                <span
                  className="search-link"
                  onClick={() => navigate("/search")}
                >
                  поиском
                </span>{" "}
              </b>
              или{" "}
              <b>
                {" "}
                <a href="#final">выбрать категорию</a>{" "}
              </b>{" "}
              болезни ниже.
            </label>
          </div>
          {mainBtn()}
          {/* <StoriesComponent /> */}
          <div className="actual">
            {highlights.map((h) => {
              return (
                <div
                  className="story"
                  key={h.id}
                  onClick={() => OpenStories(h.imagesUrls)}
                >
                  <div
                    style={{
                      backgroundImage: `url(${h.coverUrl})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                  <label>{h.name}</label>
                </div>
              );
            })}
            {/* <div className="story" onClick={() => setStoriesOpen(true)}>
              <div />
              <label>О нас</label>
            </div>
            <div className="story">
              <div />
              <label>Лайфхаки</label>
            </div>
            <div className="story">
              <div />
              <label>Вакансии</label>
            </div>
            <div className="story" onClick={() => navigate("/catalog")}>
              <div />
              <label>Юр. лицам</label>
            </div>
            <div className="story" onClick={() => navigate("/catalog")}>
              <div />
              <label>Оптовикам</label>
            </div> */}
          </div>
          {/* <StoriesComponent /> */}
          <div className="categories-group">
            <div className="categories">
              {categories.map((category, index) => {
                if (categories.length - 1 === index) {
                  return (
                    <div
                      key={category.name}
                      id="final"
                      className="category"
                      style={{
                        backgroundImage: `url(${category.imageUrl})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      onClick={() => navigate(`diseases/${category.id}`)}
                    >
                      <label>{category.name}</label>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={category.name}
                      className="category"
                      style={{
                        backgroundImage: `url(${category.imageUrl})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      onClick={() => navigate(`diseases/${category.id}`)}
                    >
                      <label>{category.name}</label>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <Footer pageName="Home" />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={storiesOpen}
          onClose={() => setStoriesOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={storiesOpen}>
            <Box sx={style}>
              <motion.div
                drag="y"
                style={{ y: y, opacity, height: "100%" }}
                dragConstraints={{ top: 0, bottom: 0 }}
              >
                <Stories
                  onStoryEnd={() => setStoriesOpen(false)}
                  stories={actualStories}
                  defaultInterval={10000}
                  width={"100%"}
                  height={"100%"}
                />
              </motion.div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <Alert
        open={alertData.open}
        title={alertData.title}
        content={alertData.content}
        handleClose={alertData.handleClose}
      />
    </>
  );
}
