import React from "react";
import "./Footer.scss";

// icons for bottom navbar
import { ReactComponent as HouseIcon } from "../../Files/Icons/house.svg";
import { ReactComponent as SearchIcon } from "../../Files/Icons/search.svg";
import { ReactComponent as HeartIcon } from "../../Files/Icons/heart.svg";
import { ReactComponent as PersonCircleIcon } from "../../Files/Icons/person-circle.svg";
import { ReactComponent as BoxIcon } from "../../Files/Icons/box.svg";

import { ReactComponent as NewsIcon } from "../../Files/Icons/news.svg";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { useNavigate } from "react-router-dom";

export default function Footer(props) {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <BottomNavigation value={props.pageName}>
        <BottomNavigationAction
          value={"Home"}
          icon={<HouseIcon />}
          onClick={() => navigate("/")}
        />
        <BottomNavigationAction
          value={"Search"}
          icon={<SearchIcon />}
          onClick={() => navigate("/search")}
        />
        <BottomNavigationAction
          value={"News"}
          icon={<NewsIcon />}
          onClick={() => navigate("/news")}
        />
        {/* <BottomNavigationAction
          value={"Notifications"}
          icon={<HeartIcon />}
          onClick={() => navigate("/notification")}
        /> */}
        <BottomNavigationAction
          value={"Profile"}
          icon={<PersonCircleIcon />}
          onClick={() => navigate("/profile")}
        />
      </BottomNavigation>
    </div>
  );
}
