import React, { useState, useEffect } from "react";
import "./Orders.scss";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Alert from "../../Components/Alert/Alert";

export default function Orders() {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);

  const [cartCount, setCartCount] = useState(0);

  const [alertData, setAlertData] = useState({
    open: false,
    title: "",
    content: "",
    handleClose: () => setAlertData((prev) => ({ ...prev, open: false })),
  });

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (!token) {
      return navigate("/profile");
    }

    axios
      .get("https://апи.звеножизни.рф/api/carts/UserOrders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setOrders(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response.data);
      });

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  }, [navigate]);

  return (
    <div className="orders">
      <Header count={cartCount} />
      <h2>Заказы</h2>
      {orders.map((order) => {
        return (
          <div key={order.id} className="order">
            <label>
              <strong>Стоимость</strong>
              <br />
              {order.price} ₽
            </label>

            <label>
              <strong>Статус</strong>
              <br />
              {order.status}
            </label>

            <label>
              <strong>Дата и время заказа:</strong>
              <br />
              {order.createdDateTime}
            </label>

            <label>
              <strong>Адрес:</strong>
              <br />
              {order.address}
            </label>

            <button
              onClick={() =>
                setAlertData((prev) => ({
                  ...prev,
                  open: true,
                  title: "Товары",
                  content: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "1rem",
                      }}
                    >
                      {order.products.map((product) => {
                        return (
                          <div
                            className="product"
                            style={{
                              borderBottom: "1px solid lightgray",
                              display: "flex",
                              flexDirection: "column",
                              rowGap: ".5rem",
                              paddingBottom: ".5rem",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "auto",
                                margin: "auto",
                              }}
                              src={product.imageURL}
                              alt={"Обложка" + product.name}
                            />
                            <label>
                              <strong>Наименование: </strong>
                              {product.name}
                            </label>
                            <label>
                              <strong>Стоимость:</strong> {product.price}₽
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  ),
                }))
              }
            >
              Показать товары
            </button>
          </div>
        );
      })}
      <Footer pageName="Profile" />
      <Alert
        open={alertData.open}
        title={alertData.title}
        content={alertData.content}
        handleClose={alertData.handleClose}
      />
    </div>
  );
}
