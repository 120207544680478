import React, { useEffect, useState } from "react";
import "./News.scss";

import NewsImage from "../../Files/Images/catalog_3.png";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import axios from "axios";

export default function News() {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token === null) return;

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setCartCount]);

  return (
    <>
      <Header count={cartCount} />
      <div className="news">
        <div className="news-block">
          <label>🎉 Великолепные новости от ООО ТД Градалис!</label>
          {/* <img src={NewsImage} alt="" /> */}
          <label className="news-content">
            Теперь наша уникальная серия продукции нанококтейлей доступна не
            только на нашем сайте, но и на популярных маркетплейсах WildBerries
            и Ozon! Мы рады представить вам возможность приобрести наши товары
            поштучно и с удобством на двух крупнейших онлайн площадках России и
            с доставкой прямо к дому. <br />
            <br />
            Ссылка на наш бренд на WildBerries -
            <a href="https://www.wb.ru/brands/310659947-Звено%20Жизни/all">
              https://www.wb.ru/brands/310659947-Звено%20Жизни/all
            </a>
            <br />
            <br />
            Теперь вы можете выбирать и заказывать наши товары в одиночку, а
            также наслаждаться привычным уровнем качества и сервисом на
            платформах, которые вы знаете и доверяете.
          </label>
          <label>
            <span>Дата публикации: </span>21.08.2023
          </label>
        </div>
        {/* <div className="news-block">
          <label>Новость вторая!</label>
          <img src={NewsImage} alt="" />
          <label>
            <span>Дата публикации: </span>01.09.2022
          </label>
        </div>
        <div className="news-block">
          <label>Новость третья!</label>
          <img src={NewsImage} alt="" />
          <label>
            <span>Дата публикации: </span>01.09.2022
          </label>
        </div>
        <div className="news-block">
          <label>Новость четвертая!</label>
          <img src={NewsImage} alt="" />
          <label>
            <span>Дата публикации: </span>01.09.2022
          </label>
        </div> */}
        <Footer pageName="News" />
      </div>
    </>
  );
}
