import React, { useEffect, useState } from "react";
import "./Cart.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Alert from "../../Components/Alert/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import InputMask from "react-input-mask";

export default function Cart() {
  const navigate = useNavigate();
  const [cartCount, setCartCount] = useState(0);
  const [isLoaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAdress, setUserAdress] = useState("");
  const [userId, setUserId] = useState("");
  const [contactType, setContactType] = useState(0);
  const [paymentType, setPaymentType] = useState(0);
  const [userPoints, setUserPoints] = useState(0);
  const [alertData, setAlertData] = useState({
    open: false,
    title: "",
    content: "",
    handleClose: () => setAlertData((prev) => ({ ...prev, open: false })),
  });
  // оформление заказа. шаг 1 из 2.
  const [createOrderStep1, setCreateOrderStep1] = useState(false);
  const handleOpenCreateOrderFirstStep = () => setCreateOrderStep1(true);
  const handleCloseCreateOrderFirstStep = () => setCreateOrderStep1(false);

  // оформление заказа. шаг 2 из 2.
  const [createOrderStep2, setCreateOrderStep2] = useState(false);

  const style = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    gap: "1rem",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "white",
    boxShadow: 10,
    p: 3,
    lineHeight: "2",
  };

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token === null) {
      // navigate("/");
      return alert(
        "Для просмотра корзины необходимо войти в аккаунт или зарегистрироваться."
      );
    }

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (resp) {
        console.error(resp.data);
        setCartCount(resp.data.productslength);
        setProducts(resp.data.products);
        setUserId(resp.data.userId);
        setUserPoints(resp.data.points);
        setContactType(resp.data.contactType);

        // console.warn(resp.data.contactType);

        setLoaded(true);
        let fPrice = 0;
        resp.data.products.forEach((product) => {
          fPrice += product.price;
        });
        setAmount(fPrice);

        setUserEmail(resp.data.userEmail);
        setUserPhone(
          resp.data.userPhone
            .replace(/ /g, "")
            .replace(/[{()}]/g, "")
            .substring(1)
        );
        setUserAdress(resp.data.userAddress);
      })
      .catch(function (error) {
        alert(error.response.data);
        if (error.response.status === 401) {
          alert(
            "Для просмотра корзины необходимо войти в аккаунт или зарегистрироваться."
          );
        }
      });
  }, [navigate, setCartCount, setProducts]);

  function getUnique(products, index) {
    const unique = products
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => products[e])
      .map((e) => products[e]);

    return unique;
  }

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        `https://апи.звеножизни.рф/api/carts/RemoveFromCart?productId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(function (response) {
        console.log(response);
        axios
          .get("https://апи.звеножизни.рф/api/carts/usercart", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(function (resp) {
            setCartCount(resp.data.length);
            setProducts(resp.data.products);

            let fPrice = 0;
            resp.data.products.forEach((product) => {
              fPrice += product.price;
            });
            setAmount(fPrice);
          })
          .catch(function (error) {
            return setAlertData((prev) => ({
              ...prev,
              open: true,
              title: "Ошибка",
              content: error.response.data,
            }));
            // if (error.response.status === 401) {
            //   alert("Для просмотра корзины необходимо войти в аккаунт.");
            // }
          });
      })
      .catch(function (error) {
        return setAlertData((prev) => ({
          ...prev,
          open: true,
          title: "Ошибка",
          content: error.response.data,
        }));
      });
  };

  const handleAddCount = (productId) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        `https://апи.звеножизни.рф/api/carts/AddToCart?productId=${productId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (resp) {
        axios
          .get("https://апи.звеножизни.рф/api/carts/usercart", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(function (resp) {
            setCartCount(resp.data.length);
            setProducts(resp.data.products);
            // console.log(resp);

            let fPrice = 0;
            resp.data.products.forEach((product) => {
              fPrice += product.price;
            });
            setAmount(fPrice);
          })
          .catch(function (error) {
            return setAlertData((prev) => ({
              ...prev,
              open: true,
              title: "Ошибка",
              content: error.response.data,
            }));
          });
      })
      .catch(function (error) {
        return setAlertData((prev) => ({
          ...prev,
          open: true,
          title: "Ошибка",
          content: error.response.data,
        }));
      });
  };

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  const buyProducts = () => {
    if (products.length === 0) {
      return;
    }

    if (!userAdress) {
      return setAlertData((prev) => ({
        ...prev,
        open: true,
        title: "Внимание",
        content: "Для продолжения необходимо заполнить адрес",
        handleClose: () => {
          setAlertData((prev) => ({ ...prev, open: false }));
          handleOpenCreateOrderFirstStep(true);
        },
      }));
    }

    if (!userPhone) {
      return setAlertData((prev) => ({
        ...prev,
        open: true,
        title: "Внимание",
        content: "Для продолжения необходимо заполнить номер для связи.",
        handleClose: () => {
          setAlertData((prev) => ({ ...prev, open: false }));
          handleOpenCreateOrderFirstStep(true);
        },
      }));
    }

    let random = uuidv4();

    let items = [];

    products.forEach((product) => {
      let itemObj = {
        Name: product.name,
        Quantity: 1,
        Amount: product.price * 100,
        Price: product.price * 100,
        Tax: "none",
      };

      items.push(itemObj);
    });

    let token = localStorage.getItem("token");

    let am = 0;

    products.forEach((product) => {
      am += product.price;
    });

    if (paymentType === 1) {
      if (userPoints < amount) {
        return setAlertData((prev) => ({
          ...prev,
          title: "Внимание",
          content: "Недостаточно баллов для совершения покупки.",
          open: true,
        }));
      }

      axios
        .post(
          `https://апи.звеножизни.рф/api/carts/create-order?orderId=${random}&contactType=${contactType}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(() => {
          axios
            .get(
              `https://апи.звеножизни.рф/api/carts/points-order-notification?orderId=${random}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              return window.open("https://звеножизни.рф/payment-success");
            })
            .catch((err) => {
              return setAlertData((prev) => ({
                ...prev,
                title: "Внимание",
                content:
                  "Время оформления заказа что-то пошло не так. Свяжитесь с тех. поддержкой для получения консультации.",
                open: true,
              }));
            });
        })
        .catch((error) => {
          console.log(error);
          return setAlertData((prev) => ({
            ...prev,
            open: true,
            title: "Ошибка",
            content:
              "Во время оформления заказа что-то пошло не так. Попробуйте позже.",
          }));
        });
    }

    axios
      .post(`https://securepay.tinkoff.ru/v2/Init`, {
        TerminalKey: "1670336658619",
        Amount: am * 100,
        OrderId: random,
        NotificationURL:
          "https://апи.звеножизни.рф/api/carts/order-notification",
        SuccessURL: "https://звеножизни.рф/payment-success",
        Receipt: {
          Email: userEmail,
          Phone: userPhone,
          EmailCompany: "white-smoke@bk.ru",
          Taxation: "usn_income",
          Items: items,
        },
      })
      .then((resp) => {
        console.log(resp);
        let url = resp.data.PaymentURL;
        axios
          .post(
            `https://апи.звеножизни.рф/api/carts/create-order?orderId=${random}&contactType=${contactType}`,
            {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(() => {
            window.open(url, "_self");
          })
          .catch((error) => {
            console.log(error);
            return setAlertData((prev) => ({
              ...prev,
              open: true,
              title: "Ошибка",
              content:
                "Во время оформления заказа что-то пошло не так. Попробуйте позже.",
            }));
          });
      })
      .catch((error) => {
        console.log(error);
        return setAlertData((prev) => ({
          ...prev,
          open: true,
          title: "Ошибка",
          content:
            "Во время оформления заказа что-то пошло не так. Попробуйте позже.",
        }));
      });
  };

  const updateAccount = () => {
    let token = localStorage.getItem("token");

    if (token === null) return;

    if (userAdress.length < 15) {
      return alert("Укажите корректный адрес доставки.");
    }

    if (userPhone.length < 10) {
      return alert("Укажите корректный номер телефона.");
    }

    axios
      .post(
        `https://апи.звеножизни.рф/api/auth/update-user?userId=${userId}&userAddress=${userAdress}&userPhone=${userPhone}&contactType=${contactType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        console.warn(response.data.contactType);
        setUserAdress(response.data.userAddress);
        setUserPhone(response.data.userPhone);
        setContactType(response.data.contactType);
        handleCloseCreateOrderFirstStep(true);
        setAlertData((prev) => ({
          ...prev,
          handleClose: () => setAlertData((prev) => ({ ...prev, open: false })),
        }));
        setCreateOrderStep1(false);
        setCreateOrderStep2(true);
      })
      .catch((error) => {
        console.log(error);
        return setAlertData((prev) => ({
          ...prev,
          open: true,
          title: "Ошибка",
          content: "Во время сохранения что-то пошло не так.",
          handleClose: () => setAlertData((prev) => ({ ...prev, open: false })),
        }));
      });
  };

  return (
    <>
      <Header count={cartCount} />
      <div className="cart">
        {isLoaded ? (
          getUnique(products, "id").map((product) => {
            return (
              <div id={product.id} key={product.id}>
                <div className="product">
                  <img
                    src={product.imageURL}
                    width={"100%"}
                    height={"auto"}
                    alt=""
                  />
                  <div className="description">
                    <label>{product.price} ₽</label>
                    <label>{product.name}</label>
                    <label>{product.description}</label>
                  </div>
                </div>
                <div className="buttons">
                  <span onClick={() => handleDelete(product.id)}>Удалить</span>
                  <span>
                    <svg
                      onClick={() => handleDelete(product.id)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                    </svg>
                    {products.length} шт.
                    <svg
                      onClick={() => handleAddCount(product.id)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <div className="product">
              <Skeleton variant="rectangular" width={"20%"} height={"75px"} />
              <div className="description">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={210} height={20} />
                  <Skeleton variant="rounded" width={210} height={20} />
                  <Skeleton variant="rounded" width={210} height={20} />
                </Stack>
              </div>
            </div>
            <div className="buttons">
              <span>
                <Skeleton variant="rounded" width={100} height={20} />
              </span>
              <span>
                <Skeleton variant="rounded" width={20} height={20} />
                <Skeleton variant="rounded" width={20} height={20} />
                <Skeleton variant="rounded" width={20} height={20} />
                <Skeleton variant="rounded" width={20} height={20} />
              </span>
            </div>
          </div>
        )}
        <div className="last-btn">
          <label>Итого: {amount} ₽</label>
          <span
            onClick={() => {
              if (amount < 5000) {
                return setAlertData((prev) => ({
                  ...prev,
                  open: true,
                  title: "Внимание",
                  content: "Минимальная сумма заказа составляет 5 000 рублей. ",
                }));
              } else {
                setCreateOrderStep1(true);
              }
            }}
          >
            Перейти к оформлению
          </span>
        </div>
        <Footer />
      </div>

      {/* Оформление заказа. Шаг 1 из 2. */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={createOrderStep1}
        onClose={handleCloseCreateOrderFirstStep}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={createOrderStep1}>
          <Box sx={style}>
            <Typography
              sx={{
                fontFamily: "Raleway, sans-serif",
                fontWeight: 600,
                // marginLeft: "0.5rem",
                margin: "auto",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Оформление заказа. Шаг 1 из 2.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 500,
                  marginLeft: "0.5rem",
                }}
                id="modal-modal-description"
              >
                Адрес доставки
              </Typography>
              <input
                placeholder={userAdress}
                value={userAdress}
                onChange={(e) => setUserAdress(e.target.value)}
              ></input>
              <Typography
                sx={{
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 500,
                  marginLeft: "0.5rem",
                }}
                id="modal-modal-description"
              >
                Номер телефона
              </Typography>
              <InputMask
                mask={"+7 (999) 999 99 99"}
                maskChar={""}
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
              >
                {(e) => <input />}
              </InputMask>
              <FormControl style={{ marginLeft: ".1rem" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  <Typography
                    sx={{
                      fontFamily: "Raleway, sans-serif",
                      fontWeight: 500,
                      marginLeft: "0.5rem",
                      color: "black",
                    }}
                    id="modal-modal-description"
                  >
                    Способ связи
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={contactType}
                  onChange={(e) => setContactType(e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Связаться по телефону;"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Связаться через WhatsApp;"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Связаться через Telegram;"
                  />
                </RadioGroup>
              </FormControl>
            </Typography>
            <button className="btn-color" onClick={updateAccount}>
              Продолжить
            </button>
          </Box>
        </Fade>
      </Modal>
      <Alert
        open={alertData.open}
        title={alertData.title}
        content={alertData.content}
        handleClose={alertData.handleClose}
      />

      {/* Оформление заказа. Шаг 2 из 2. */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={createOrderStep2}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={() => setCreateOrderStep2(false)}
      >
        <Fade in={createOrderStep2}>
          <Box sx={style}>
            <Typography
              sx={{
                fontFamily: "Raleway, sans-serif",
                fontWeight: 600,
                // marginLeft: "0.5rem",
                margin: "auto",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Оформление заказа. Шаг 2 из 2.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              <FormControl style={{ marginLeft: ".1rem" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  <Typography
                    sx={{
                      fontFamily: "Raleway, sans-serif",
                      fontWeight: 500,
                      marginLeft: "0.5rem",
                      color: "black",
                    }}
                    id="modal-modal-description"
                  >
                    Тип оплаты
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={0}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Картой онлайн;"
                    onClick={() => setPaymentType(0)}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Баллами;"
                    onClick={() => setPaymentType(1)}
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <Typography
                sx={{
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 500,
                  marginLeft: "0.5rem",
                }}
                id="modal-modal-description"
              >
                Ваше количество баллов: {userPoints}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 500,
                  marginLeft: "0.5rem",
                  opacity: 0.6,
                  fontSize: "1rem",
                }}
                id="modal-modal-description"
              >
                Баллами можно оплатить только 100% от покупки.
              </Typography>
            </Typography>
            <button className="btn-color" onClick={() => buyProducts()}>
              Перейти к покупке
            </button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
