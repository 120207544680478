import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function Alert(props) {
  const buttonStyle = {
    width: "100%",
    margin: "auto",
    padding: ".5rem 0",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    fontWeight: "700",
    border: "1px solid lightgray",
    outline: "none",
    fontFamily: `"Raleway", sans-serif`,
    fontSize: "1rem",
    marginBottom: ".5rem",
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={props.handleClose} style={buttonStyle}>
          Продолжить
        </button>
      </DialogActions>
    </Dialog>
  );
}
