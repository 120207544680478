import React from "react";
import "./PaymentSuccess.scss";

import Logo from "../../Files/Icons/logo.svg";

import { useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const navigate = useNavigate();

  return (
    <div className="payment-success">
      <img src={Logo} alt="Звено жизни" />
      <label>
        Поздравляем! Покупка завершена. <br /> C Вами свяжутся для уточнения
        дополнительной информации. <br /> <br />
        Все заказы вы можете посмотреть на странице <i>"Заказы"</i> в разделе{" "}
        <i>"Профиль"</i>.
      </label>
      <button
        onClick={() => navigate("/profile/orders")}
        style={{ fontWeight: 500 }}
      >
        Посмотреть заказы
      </button>
      <button onClick={() => navigate("/")}>На главную</button>
    </div>
  );
}
