import "./App.css";
import Home from "./Pages/Home/Home";
import { Routes, Route } from "react-router-dom";
import ProductInfo from "./Pages/ProductInfo/ProductInfo";
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/Signup/SignUp";
import Search from "./Pages/Search/Search";
import Notification from "./Pages/Notification/Notification";
import Products from "./Pages/Products/Products";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Catalog from "./Pages/Catalog/Catalog";
import Profile from "./Pages/Profile/Profile";
import News from "./Pages/News/News";
import Cart from "./Pages/Cart/Cart";
import Diseases from "./Pages/Diseases/Diseases";

import CookieConsent from "react-cookie-consent";
import PaymentSuccess from "./Pages/PaymentSuccess/PaymentSuccess";
import Orders from "./Pages/Orders/Orders";
import Points from "./Pages/Points/Points";

function App() {
  return (
    <div className="App">
      <CookieConsent
        location="bottom"
        buttonText="Хорошо"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#4e503b",
          background: "#fff",
          fontSize: "20px",
          borderRadius: "10px",
        }}
        expires={150}
        // debug={true}
      >
        <div>
          Этот веб-сайт использует файлы cookie, которые помогают веб-сайту
          функционировать а также для отслеживания того, как вы взаимодействуете
          с нашим веб-сайтом.
        </div>
        <small style={{ fontSize: "12px" }}>
          Но для того, чтобы мы могли обеспечить наилучший пользовательский
          опыт, для соглашения нажмите "Хорошо".
        </small>
      </CookieConsent>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<ProductInfo />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="signup/:code" element={<SignUp />} />
        <Route path="search" element={<Search />} />
        <Route path="notification" element={<Notification />} />
        <Route path="products" element={<Products />} />
        <Route path="catalog" element={<Catalog />} />
        <Route path="profile" element={<Profile />} />
        <Route path="news" element={<News />} />
        <Route path="cart" element={<Cart />} />
        <Route path="diseases/:diseaseName" element={<Diseases />} />
        <Route path="diseases" element={<Diseases />} />
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route path="/profile/orders" element={<Orders />} />
        <Route path="points" element={<Points />} />
      </Routes>
    </div>
  );
}

export default App;
