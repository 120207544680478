import React, { useState, useEffect } from "react";
import "./Catalog.scss";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import Img1 from "../../Files/Images/catalog_1.png";
import Img2 from "../../Files/Images/catalog_2.png";
import Img3 from "../../Files/Images/catalog_3.png";
import Btl from "../../Files/Images/bottle.jpg";

import Footer from "../../Components/Footer/Footer";

import axios from "axios";

import { Modal, Box, Typography, Skeleton } from "@mui/material";
import Header from "../../Components/Header/Header";

export default function Catalog() {
  const [open, setOpen] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  const ModalStyle = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "#161616",
    boxShadow: 10,
    p: 3,
    lineHeight: "2",
  };

  const handleOpen = (product) => {
    setActiveProduct(product);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState({
    id: 0,
    name: "",
    description: "",
  });

  useEffect(() => {
    axios
      .get("https://апи.звеножизни.рф/api/Pages/Catalog")
      .then(function (resp) {
        console.log(resp);

        const newData = resp.data.map((product, index) => {
          return {
            ...product,
            hideDesc: true,
          };
        });

        setProducts(newData);
        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
        alert("При загрузке данных возникла ошибка. Пробуйте позже.");
        // if (error.response.status === 401) {
        //   alert("Для просмотра корзины необходимо войти в аккаунт.");
        // }
      });

    let token = localStorage.getItem("token");

    if (token === null) return;

    axios
      .get("https://апи.звеножизни.рф/api/carts/usercart", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setCartCount(response.data.products.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const addToCart = (productId) => {
    let token = localStorage.getItem("token");

    if (token === null) {
      return alert(
        "Чтобы добавить товар в корзину необходимо пройти регистрацию или войти в аккаунт."
      );
    }

    axios
      .post(
        `https://апи.звеножизни.рф/api/carts/AddToCart?productId=${productId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (resp) {
        console.log(resp);
        setCartCount(resp.data);
      })
      .catch(function (error) {
        console.log(error);
        // if (error.response.status === 401) {
        //   alert("Для просмотра корзины необходимо войти в аккаунт.");
        // }
      });
  };

  // const testLabel = (productId) => {
  //   return productId === 1 ? (
  //     <div className="product-subfooter" onClick={() => addToCart(productId)}>
  //       <label>ПОЛОЖИТЬ КОМПЛЕКТ В КОРЗИНУ</label>
  //       <label>5000 ₽</label>
  //     </div>
  //   ) : (
  //     ""
  //   );
  // };

  const ProductDescription = (desc) => {
    if (desc.lenght >= 200) {
      return (
        <label
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            whiteSpace: "pre-wrap",
          }}
        >
          {desc}
        </label>
      );
    } else {
      return <label>{desc}</label>;
    }
  };

  return (
    <>
      <Header count={cartCount} />
      <div className="catalog">
        <div className="products">
          {isLoaded ? (
            products.map((product) => {
              return (
                <div className="product" key={product.name}>
                  <div className="product-header">
                    <label>{product.name}</label>
                  </div>
                  <div className="product-body">
                    <img src={product.imageURL} alt="" />
                    {product.hideDesc ? (
                      <label
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          whiteSpace: "pre-wrap",
                          // height: "6rem",
                          overflow: "hidden",
                        }}
                      >
                        {product.description.slice(0, 150)}...{" "}
                        <span
                          style={{ color: "blue" }}
                          onClick={
                            () =>
                              setProducts((prev) => {
                                let ns = prev.map((pr) => {
                                  if (pr.id === product.id) {
                                    return { ...pr, hideDesc: false };
                                  }

                                  return pr;
                                });
                                return ns;
                              })
                            // console.log("CLICK")
                          }
                        >
                          Показать больше
                        </span>
                      </label>
                    ) : (
                      <label
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          whiteSpace: "pre-wrap",
                          // height: "6rem",
                          overflow: "hidden",
                        }}
                      >
                        {product.description}
                      </label>
                    )}
                  </div>
                  <div
                    className="product-footer"
                    style={{ display: product.price === 0 ? "none" : "flex" }}
                  >
                    <div className="price">₽{product.price}</div>
                    {/* <div className="add-to-cart">Добавить в корзину</div>
                     */}
                    <div
                      className="add-to-cart"
                      onClick={() => addToCart(product.id)}
                    >
                      <label htmlFor={"cartBtn"}>
                        <ShoppingCartOutlinedIcon id={"cartBtn"} />
                      </label>
                      <label>в корзину</label>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div className="post">
                <div className="post-header">
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"25%"}
                    height={"35px"}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="post-content">
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={"347px"}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="post-footer">
                  <div>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"80px"}
                      height="25px"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"80px"}
                      height="25px"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="post">
                <div className="post-header">
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"25%"}
                    height={"35px"}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="post-content">
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={"347px"}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="post-footer">
                  <div>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"80px"}
                      height="25px"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"80px"}
                      height="25px"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="post">
                <div className="post-header">
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"25%"}
                    height={"35px"}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="post-content">
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={"347px"}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="post-footer">
                  <div>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"80px"}
                      height="25px"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"80px"}
                      height="25px"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Footer pageName="Catalog" />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflow: "scroll",
          }}
        >
          <Typography variant="div">
            <Box sx={ModalStyle} key={activeProduct.id}>
              <Box style={{ margin: "auto" }}>
                <b>{activeProduct.name}</b>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 1,
                  paddingTop: 1,
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <button className="btn-color" onClick={handleClose}>
                  Закрыть
                </button>
                <button
                  className="btn-color"
                  // onClick={() => buyProduct(activeProduct.id)}
                >
                  Войти в аккаунт
                </button>
              </Box>
            </Box>
          </Typography>
        </Modal>
      </div>
    </>
  );
}
